import { RouteInfo } from './sidebar.metadata';

export const STOREROUTES: RouteInfo[] = [
  {
    path: '/side-bar-view/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-chart-pie',
    class: 'nav-small',
    directRoute : true,
    extralink: false,
    submenu: [
      {
        path: '/dashboard/store-dashboard',
        title: 'Dashboard',
        icon: 'mdi mdi-chart-pie',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/home',
        title: 'Home',
        icon: 'mdi mdi-home',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/table-booking',
        title: 'Table Booking',
        icon: 'mdi mdi-table',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/booking-info',
        title: 'Booking Information',
        icon: 'mdi mdi-book',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/send-sms',
        title: 'Send SMS',
        icon: 'mdi mdi-book',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/dashboard/feed-back-dashboard',
        title: 'Feed Back',
        icon: 'mdi mdi-chart-pie',
        class: '',
        extralink: false,
        submenu: []
      },


      // {
      //   path: '/admin/pos-system',
      //   title: 'POS System',
      //   icon: 'mdi mdi-account',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
    ]
  },


  {
    path: '/side-bar-view/menu',
    title: 'Menu',
    icon: 'mdi mdi-menu',
    class: 'nav-small',
    directRoute : true,
    extralink: false,
    submenu: [
      {
        path: '/menu',
        title: 'Menu',
        icon: 'mdi mdi-file-document-box',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/menu/products',
        title: 'Products',
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/menu/menu-combo',
      //   title: 'Menu Combo',
      //   icon: 'mdi mdi-file-document-box',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/menu/combo-menu-categories',
        title: 'Combo Categories',
        icon: 'mdi mdi-file-document-box',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/menu/menu-groups',
        title: 'Menu Groups',
        icon: 'mdi mdi-file-document-box',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/menu/product',
      //   title: 'Product',
      //   icon: 'mdi mdi-silverware-variant',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },

      // {
      //   path: '/menu/product-combo',
      //   title: 'Product Combo',
      //   icon: 'mdi mdi-silverware-variant',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/menu/combo-products',
        title: 'Combo Products',
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/menu/addon',
      //   title: 'Addon Product',
      //   icon: 'mdi mdi-silverware-variant',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/menu/add-ons',
        title: 'Addon Products',
        params : {url : true},
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/menu/menu-pdf-download',
        title: 'Digital Menu PDF',
        params : {url : true},
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/catering/catering-category',
        title: 'Catering Category',
        params : {url : true},
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/catering/catering-products',
        title: 'Catering Products',
        params : {url : true},
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/product-points',
        title: 'Product Points',
        params : {url : true},
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },


  {
    path: '/side-bar-view/assets',
    title: 'Assets',
    icon: 'mdi mdi-food',
    class: 'nav-small',
    directRoute:true,
    extralink: false,
    submenu: [
      {
        path: '/assets/table',
        title: 'Table',
        icon: 'mdi mdi-table',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/assets/employee',
        title: 'Users',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/assets/staffs',
        title: 'Employees',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },

  {
    path: '/side-bar-view/settings',
    title: 'Settings',
    icon: 'mdi mdi-settings',
    class: 'nav-small',
    directRoute:true,
    extralink: false,
    submenu: [

      {
        path: '/settings/reservation-settings',
        title: 'Reservation Settings',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/settings/store-settings',
        title: 'Store Settings',
        icon: 'mdi mdi-store',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/settings/delivery-settings',
        title: 'Delivery Settings',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/settings/takeaway-settings',
        title: 'Takeaway Settings',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/settings/pos-settings',
        title: 'POS Settings',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/settings/printer',
        title: 'Printer',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/settings/printer/printer-type',
        title: 'Printer Type',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/admin/discount-reasons/discount-reasons',
      //   title: 'Discount Reasons',
      //   icon: 'mdi mdi-label',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/admin/offers-mapping/manage-offers',
        title: 'Manage Offers',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/counters',
        title: 'Counters',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },

    ]
  },

  {
    path: '/side-bar-view/ratings',
    title: 'Ratings',
    icon: 'mdi mdi-star',
    class: 'nav-small',
    directRoute:true,
    extralink: false,
    submenu: [
      {
        path: '/rating/store-rating',
        title: 'Store Rating',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/rating/product-rating',
        title: 'Product Rating',
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },


  {
    path: '/side-bar-view/reports',
    title: 'Reports',
    icon: 'mdi mdi-file-document-box',
    class: 'nav-small',
    directRoute:true,
    extralink: false,
    submenu: [
      {
        path: '/reports/store-reports/orderreport',
        title: 'Order History',
        icon: 'mdi mdi-history',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/subscribed-customers-report',
        title: 'Subscribed Customers',
        icon: 'mdi mdi-history',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/tablereport',
        title: 'Table History',
        icon: 'mdi mdi-chart-gantt',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/salesreport',
        title: 'Sales Report By Product',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-report-by-category',
        title: 'Sales Report By Category',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-report-by-date',
        title: 'Sales Report By Date',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/product-detailed-report',
        title: 'Product Detailed Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/complementary-report',
        title: 'Complementary Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/cancel-report',
        title: 'Cancelled Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/void-sales-report',
        title: 'Void Sales Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/discount-report',
        title: 'Discount Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/expense-report',
        title: 'Expense Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/feed-back-form-report',
        title: 'Feedback Form Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/reports/store-reports/salesman-wise-sales-report',
        title: 'Sales Report by Salesman',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-summary-consolidated-report',
        title: 'Daily Sales Summary',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-checkin-summary-consolidated-report',
        title: 'Sales Summary by Checkin',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-summary-consolidated-report-by-date',
        title: 'Sales Summary Consolidated',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/advanced-sales-summary-consolidated-report-by-date',
        title: 'Advanced Sales Summary',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/driver-assign-report',
        title: 'Driver Assign Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/checkin-checkout-report',
        title: 'Checkin Checkout Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/driver',
        title: 'Driver Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/customer',
        title: 'Customer',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/admin-reports/log-reports/store-log-report',
        title: 'Store Log Report',
        icon: 'mdi mdi-history',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/customer-credit-report',
        title: 'Credit Report',
        icon: 'mdi mdi-history',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/send-sms/send-sms-log',
        title: 'SMS Log Report',
        icon: 'mdi mdi-book',
        class: '',
        extralink: false,
        submenu: []
      },


      {
        path: '/reports/loyalty-reports',
        title: 'Loyalty Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/loyalty-reports/customer-loyalty-report',
        title: 'Customer Loyalty Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/loyalty-reports/loyalty-points-report',
        title: 'Loyalty Points Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/loyalty-reports/loyalty-points-log-report',
        title: 'Loyalty Points Log Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/catering-report',
        title: 'Catering Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/catering-payment-report',
        title: 'Catering Payment Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/catering-sales-report',
        title: 'Catering Sales Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/waiter-points-report',
        title: 'Waiter Points Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/employee-attendance-report',
        title: 'Employee Attendance Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/reports/store-reports/sales-report-by-category-detailed',
        title: 'Sales Category Detailed Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },


    ]
  },
  // {
  //   path: '',
  //   title: 'Inventory',
  //   icon: 'mdi mdi-file-document-box',
  //   class: 'nav-small',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: 'inventory/brand',
  //       title: 'Brand',
  //       icon: 'mdi mdi-history',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: 'inventory/category',
  //       title: 'Category',
  //       icon: 'mdi mdi-history',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: 'inventory/subcategory',
  //       title: 'Sub Category',
  //       icon: 'mdi mdi-history',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: 'inventory/supplier',
  //       title: 'Supplier',
  //       icon: 'mdi mdi-history',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: 'inventory/product',
  //       title: 'Product',
  //       icon: 'mdi mdi-history',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: 'inventory/purchase',
  //       title: 'Purchase',
  //       icon: 'mdi mdi-history',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: 'inventory/stock-list',
  //       title: 'Stock List',
  //       icon: 'mdi mdi-history',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: 'inventory/inventory-reports',
  //       title: 'Inventory Reports',
  //       icon: 'mdi mdi-history',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //   ]
  // },






];
