export class Constants {

  public static get roleIdMenu1() {

    return [
      {
        id: 1,
        path :'/assets/office/officemapping'
      },
      {
        id: 2,
        path :'/admin/category/categorymapping'
      },
      {
        id: 3,
        path :'/organization/store/storeservicemapping'
      },
      {
        id: 4,
        path :'/organization/company'
      },
      {
        id: 5,
        path :'/organization/store'
      },
      {
        id: 6,
        path :'/home'
      },
      {
        id: 7,
        path :'/menu'
      },
      {
        id: 8,
        path :'/menu/product'
      },
      {
        id: 9,
        path :'/assets/table'
      },
      {
        id: 10,
        path :'/assets/office'
      },
      {
        id: 11,
        path :'/assets/employee'
      },
      {
        id: 12,
        path :'/settings/subscription/plan'
      },
      {
        id: 13,
        path :'/settings/admin-settings/location'
      },
      {
        id: 14,
        path :'/settings/admin-settings/store-category'
      },
      {
        id: 13,
        path :'/settings/admin-settings/payment-mapping'
      },
      {
        id: 14,
        path :'/reports/admin-reports/edine-consolidated-report'
      },
      {
        id: 15,
        path :'/reports/store-reports/commissionreport'
      },
      {
        id: 16,
        path :'/settings/admin-settings/store-category/mapping'
      },
      // {
      //   id: 15,
      //   path :'/admin/admin-reports/store-report'
      // },
      // {
      //   id: 16,
      //   path :'/admin/admin-reports/customer-report'
      // },
      // {
      //   id: 17,
      //   path :'/admin/admin-reports/order-report'
      // },
      // {
      //   id: 18,
      //   path :'/admin/admin-reports/store-detail-report'
      // },
      {
        id: 19,
        path :'/settings/subscription/package'
      },
      {
        id: 20,
        path :'/settings/subscription/package-builder'
      },
      {
        id: 21,
        path :'/settings/subscription'
      },
      {
        id: 22,
        path :'/dashboard/admin-dashboard'
      },
      {
        id: 23,
        path :'/settings/subscription/extend-subscription'
      },
      {
        id: 24,
        path :'/settings/subscription/subscription-settings'
      },
      {
        id: 25,
        path :'/reports/admin-reports/log-reports'
      },
      {
        id: 26,
        path :'/reports/admin-reports/subscription-reports'
      },
      {
        id: 27,
        path :'/settings/subscription/one-time-charges'
      },
      {
        id: 28,
        path :'/admin/offers-mapping'
      },
      {
        id:29,
        path: '/send-sms/send-sms-log'
      },
      {
        id:29.1,
        path: '/send-sms/custom-sms-messages'
      },
      {
        id: 30,
        path :'/loyalty'
      },
      {
        id: 31,
        path :'/organization/company-group'
      },

    ];
  }
  public static get roleIdMenu2() {
    return [
      {
        id: 1,
        path :'/dashboard/company-dashboard'
      },
      {
        id: 1,
        path :'/admin/room/roommapping'
      },
      {
        id: 5.1,
        path :'/organization/store'
      },
      {
        id:2,
        path: '/menu/product/menugroupinsert'
      },
      {
        id:3,
        path: '/menu/menusort'
      },
      {
        id:4,
        path: '/reports/store-reports/orderreport'
      },
      {
        id:5,
        path: '/reports/store-reports/tablereport'
      },
      {
        id:6,
        path: '/reports/store-reports/salesreport'
      },
      {
        id:7,
        path: '/menu/product/product-sort'
      },
      {
        id:8,
        path: '/dashboard/store-dashboard'
      },
      {
        id:9,
        path: '/home'
      },
      {
        id:10,
        path: '/admin/table-booking'
      },
      {
        id:11,
        path: '/admin/booking-info'
      },
      {
        id:12,
        path: '/menu'
      },
      {
        id:13,
        path: '/menu/product'
      },
      {
        id:13.1,
        path: '/menu/products'
      },
      {
        id:14,
        path: '/assets/table'
      },
      {
        id:15,
        path: '/assets/employee'
      },
      {
        id:16,
        path: '/admin/time-slot'
      },
      {
        id:17,
        path: '/admin/pre-booking-date-control'
      },
      {
        id:18,
        path: '/admin/pre-booking-day-control'
      },
      {
        id:19,
        path: '/admin/settings'
      },
      {
        id:20,
        path: '/settings/reservation-settings'
      },
      {
        id:21,
        path: '/settings/store-settings'
      },
      {
        id:22,
        path: '/admin/customer'
      },
      {
        id:23,
        path: '/settings/delivery-settings'
      },
      {
        id:24,
        path: '/settings/takeaway-settings'
      },
      {
        id: 25,
        path :'/rating/store-rating'
      },
      {
        id: 26,
        path :'/rating/product-rating'
      },
      {
        id: 27,
        path :'/admin/pos-system'
      },
      {
        id: 27,
        path :'/admin/layout'
      },
      {
        id: 28,
        path :'/menu/addon'
      },
      {
        id: 28.1,
        path :'/menu/add-ons'
      },
      {
        id: 29,
        path :'/menu/addon/addon-group'
      },
      {
        id: 29,
        path :'/menu/menu-combo'
      },
      {
        id: 29.1,
        path :'/menu/combo-menu-categories'
      },
      {
        id: 30,
        path :'/menu/product-combo'
      },
      {
        id: 30.1,
        path :'/menu/combo-products'
      },
      {
        id:31,
        path: '/reports/store-reports/sales-report-by-date'
      },
      {
        id:32,
        path: '/reports/store-reports/salesman-wise-sales-report'
      },
      {
        id:33,
        path: '/admin/pos-settings'
      },
      {
        id:34,
        path: '/reports/store-reports/driver-assign-report'
      },
      {
        id:35.1,
        path: '/reports/store-reports/complementary-report'
      },
      {
        id:35.2,
        path: '/reports/store-reports/cancel-report'
      },
      {
        id:35.3,
        path: '/reports/store-reports/void-sales-report'
      },
      {
        id:35.3,
        path: '/reports/store-reports/discount-report'
      },
      {
        id:36,
        path: '/reports/store-reports/sales-summary-consolidated-report'
      },
      {
        id:36.1,
        path: '/reports/store-reports/sales-summary-consolidated-report-by-date'
      },
      {
        id:36.2,
        path: '/reports/store-reports/advanced-sales-summary-consolidated-report-by-date'
      },
      {
        id:37,
        path: '/reports/store-reports/checkin-checkout-report'
      },
      {
        id:38,
        path: '/reports/store-reports/driver'
      },
      {
        id:39,
        path: '/master-settings'
      },
      {
        id:40,
        path: '/account'
      },
      {
        id:41,
        path: '/account/account-expiry'
      },
      {
        id:41,
        path: '/reports/admin-reports/log-reports/store-log-report'
      },
      {
        id:42,
        path: '/reports/subscription-reports/one-time-charges-report'
      },
      {
        id:42,
        path: '/inventory'
      },
      {
        id:43,
        path: '/inventory/brand'
      },
      {
        id:44,
        path: '/inventory/category'
      },
      {
        id:45,
        path: '/inventory/subcategory'
      },
      {
        id:46,
        path: '/inventory/product'
      },
      {
        id:47,
        path: '/inventory/purchase'
      },
      {
        id:48,
        path: '/inventory/supplier'
      },
      {
        id:49,
        path: '/inventory/purchase'
      },
      {
        id:50,
        path: '/inventory/stock-list'
      },
      {
        id:51,
        path: '/inventory/inventory-reports'
      },
      {
        id:52,
        path: '/inventory/inventory-reports/purchase-stock-report'
      },
      {
        id:53,
        path: '/inventory/inventory-reports/store-stock-report'
      },
      {
        id:54,
        path: '/reports/store-reports/expense-report'
      },
      {
        id:54.1,
        path: '/reports/store-reports/feed-back-form-report'
      },
      {
        id:55,
        path: '/reports/store-reports/customer-credit-report'
      },
      {
        id:56,
        path: '/admin/discount-reasons/discount-reasons'
      },
      {
        id:57,
        path: '/admin/offers-mapping/manage-offers'
      },
      {
        id:58,
        path: '/settings/printer'
      },
      {
        id:59,
        path: '/settings/printer/mapping'
      },
      {
        id:60,
        path: '/settings/pos-settings'
      },
      {
        id:61,
        path: '/reports/store-reports/subscribed-customers-report'
      },
      {
        id:62,
        path: '/settings/printer/printer-type'
      },
      {
        id:63,
        path: '/settings/printer/printer-type-category-mapping'
      },
      {
        id:64,
        path: '/send-sms'
      },
      {
        id:65,
        path: '/send-sms/send-sms-log'
      },
      {
        id:65.1,
        path: '/send-sms/custom-sms-messages'
      },
      {
        id:66,
        path: '/menu/company-menu-to-store'
      },
      {
        id:67,
        path: '/loyalty/loyalty-menu-group'
      },
      {
        id:67.1,
        path: '/loyalty/loyalty-type'
      },

      {
        id: 30,
        path :'/loyalty'
      },
      // {
      //   id:68,
      //   path: '/reports/loyalty-reports'
      // },
      // {
      //   id:69,
      //   path: '/reports/loyalty-reports/customer-loyalty-report'
      // },
      {
        id:70,
        path: '/reports/loyalty-reports/loyalty-points-report'
      },
      {
        id:71,
        path: '/reports/loyalty-reports/loyalty-points-log-report'
      },
      {
        id:72,
        path: 'side-bar-view/menu',
      },
      {
        id:73,
        path: '/side-bar-view/dashboard'
      },
      {
        id:74,
        path: '/feed-back-form/questions'
      },
      {
        id:75,
        path: '/reports/store-reports/sales-report-by-category'
      },
      {
        id:76,
        path: '/reports/store-reports/catering-report'
      },
      {
        id:77,
        path: '/reports/store-reports/waiter-points-report'
      },
      {
        id:78,
        path: '/reports/store-reports/sales-checkin-summary-consolidated-report'
      },
      {
        id:79,
        path: '/reports/store-reports/sales-summary-online-partners-report'
      },
      {
        id:80,
        path: '/reports/store-reports/sales-report-by-online-partner'
      },
      {
        id:81,
        path: '/reports/store-reports/catering-payment-report'
      },
      {
        id:82,
        path: '/reports/store-reports/catering-sales-report'
      },

    ];
  }
  public static get roleIdMenu3() {
    return [
      {
        id: 1,
        path :'/admin/room/roommapping'
      },
      {
        id:2,
        path: '/menu/product/menugroupinsert'
      },
      {
        id:3,
        path: '/menu/menusort'
      },
      {
        id:4,
        path: '/reports/store-reports/orderreport'
      },
      {
        id:5,
        path: '/reports/store-reports/tablereport'
      },
      {
        id:6,
        path: '/reports/store-reports/salesreport'
      },
      {
        id:6.1,
        path: '/reports/store-reports/sales-report-by-category'
      },
      {
        id:7,
        path: '/menu/product/product-sort'
      },
      {
        id:8,
        path: '/dashboard/store-dashboard'
      },
      {
        id:8.1,
        path: '/dashboard/feed-back-dashboard'
      },
      {
        id:9,
        path: '/home'
      },
      {
        id:10,
        path: '/admin/table-booking'
      },
      {
        id:11,
        path: '/admin/booking-info'
      },
      {
        id:12,
        path: '/menu'
      },
      {
        id:13,
        path: '/menu/product'
      },
      {
        id:13.1,
        path: '/menu/products'
      },
      {
        id:14,
        path: '/assets/table'
      },
      {
        id:15,
        path: '/assets/employee'
      },
      {
        id:15.1,
        path: '/assets/staffs'
      },
      {
        id:16,
        path: '/admin/time-slot'
      },
      {
        id:17,
        path: '/admin/pre-booking-date-control'
      },
      {
        id:18,
        path: '/admin/pre-booking-day-control'
      },
      {
        id:19,
        path: '/admin/settings'
      },
      {
        id:20,
        path: '/settings/reservation-settings'
      },
      {
        id:21,
        path: '/settings/store-settings'
      },
      {
        id:22,
        path: '/admin/customer'
      },
      {
        id:23,
        path: '/settings/delivery-settings'
      },
      {
        id:24,
        path: '/settings/takeaway-settings'
      },
      {
        id: 25,
        path :'/rating/store-rating'
      },
      {
        id: 26,
        path :'/rating/product-rating'
      },
      {
        id: 27,
        path :'/admin/pos-system'
      },
      {
        id: 27,
        path :'/admin/layout'
      },
      {
        id: 28,
        path :'/menu/addon'
      },
      {
        id: 28.1,
        path :'/menu/add-ons'
      },
      {
        id: 29,
        path :'/menu/addon/addon-group'
      },
      {
        id: 29,
        path :'/menu/menu-combo'
      },
      {
        id: 29.1,
        path :'/menu/combo-menu-categories'
      },
      {
        id: 30,
        path :'/menu/product-combo'
      },
      {
        id: 30.1,
        path :'/menu/combo-products'
      },
      {
        id: 30.2,
        path :'/menu/menu-groups'
      },
      {
        id:31,
        path: '/reports/store-reports/sales-report-by-date'
      },
      {
        id:32,
        path: '/reports/store-reports/salesman-wise-sales-report'
      },
      {
        id:32.2,
        path: '/reports/store-reports/employee-attendance-report'
      },
      {
        id:32.2,
        path: '/reports/store-reports/sales-report-by-category-detailed'
      },
      {
        id:32.3,
        path: '/reports/store-reports/product-detailed-report'
      },
      {
        id:32.2,
        path: '/counters'
      },
      {
        id:33,
        path: '/admin/pos-settings'
      },
      {
        id:34,
        path: '/reports/store-reports/driver-assign-report'
      },
      {
        id:35.1,
        path: '/reports/store-reports/complementary-report'
      },
      {
        id:35.2,
        path: '/reports/store-reports/cancel-report'
      },
      {
        id:36,
        path: '/reports/store-reports/sales-summary-consolidated-report'
      },
      {
        id:36.1,
        path: '/reports/store-reports/sales-summary-consolidated-report-by-date'
      },
      {
        id:36.2,
        path: '/reports/store-reports/advanced-sales-summary-consolidated-report-by-date'
      },
      {
        id:37,
        path: '/reports/store-reports/checkin-checkout-report'
      },
      {
        id:38,
        path: '/reports/store-reports/driver'
      },
      {
        id:39,
        path: '/master-settings'
      },
      {
        id:40,
        path: '/account'
      },
      {
        id:41,
        path: '/account/account-expiry'
      },
      {
        id:41,
        path: '/reports/admin-reports/log-reports/store-log-report'
      },
      {
        id:42,
        path: '/reports/subscription-reports/one-time-charges-report'
      },
      {
        id:42,
        path: '/inventory'
      },
      {
        id:43,
        path: '/inventory/brand'
      },
      {
        id:44,
        path: '/inventory/category'
      },
      {
        id:45,
        path: '/inventory/subcategory'
      },
      {
        id:46,
        path: '/inventory/product'
      },
      {
        id:47,
        path: '/inventory/purchase'
      },
      {
        id:48,
        path: '/inventory/supplier'
      },
      {
        id:49,
        path: '/inventory/purchase'
      },
      {
        id:50,
        path: '/inventory/stock-list'
      },
      {
        id:51,
        path: '/inventory/inventory-reports'
      },
      {
        id:52,
        path: '/inventory/inventory-reports/purchase-stock-report'
      },
      {
        id:53,
        path: '/inventory/inventory-reports/store-stock-report'
      },
      {
        id:54,
        path: '/reports/store-reports/expense-report'
      },
      {
        id:54.1,
        path: '/reports/store-reports/feed-back-form-report'
      },
      {
        id:55,
        path: '/reports/store-reports/customer-credit-report'
      },
      {
        id:55.1,
        path: '/reports/store-reports/void-sales-report'
      },
      {
        id:55.2,
        path: '/reports/store-reports/discount-report'
      },
      {
        id:56,
        path: '/admin/discount-reasons/discount-reasons'
      },
      {
        id:57,
        path: '/admin/offers-mapping/manage-offers'
      },
      {
        id:58,
        path: '/settings/printer'
      },
      {
        id:59,
        path: '/settings/printer/mapping'
      },
      {
        id:60,
        path: '/settings/pos-settings'
      },
      {
        id:61,
        path: '/reports/store-reports/subscribed-customers-report'
      },
      {
        id:62,
        path: '/settings/printer/printer-type'
      },
      {
        id:63,
        path: '/settings/printer/printer-type-category-mapping'
      },
      {
        id:64,
        path: '/send-sms'
      },
      {
        id:65,
        path: '/send-sms/send-sms-log'
      },
      {
        id:65.1,
        path: '/send-sms/custom-sms-messages'
      },
      {
        id:67,
        path: '/reports/loyalty-reports'
      },
      {
        id:68,
        path: '/reports/loyalty-reports/customer-loyalty-report'
      },
      {
        id:69,
        path: '/menu/menu-pdf-download'
      },
      {
        id:70,
        path: '/reports/loyalty-reports/loyalty-points-report'
      },
      {
        id:71,
        path: '/reports/loyalty-reports/loyalty-points-log-report'
      },
      {
        id:72,
        path: 'side-bar-view/menu',
      },
      {
        id:73,
        path: '/side-bar-view/dashboard'
      },
      {
        id:74,
        path: '/catering/catering-category'
      },
      {
        id:75,
        path: '/catering/catering-products'
      },
      {
        id:76,
        path: '/reports/store-reports/catering-report'
      },
      {
        id:77,
        path: '/product-points'
      },
      {
        id:78,
        path: '/reports/store-reports/waiter-points-report'
      },
      {
        id:79,
        path: '/reports/store-reports/catering-payment-report'
      },
      {
        id:80,
        path: '/reports/store-reports/sales-checkin-summary-consolidated-report'
      },
      {
        id:81,
        path: '/reports/store-reports/catering-sales-report'
      },
      


    ];
  }

  public static get roleIdMenu4() {
    return [
      {
        id:1,
        path: '/side-bar-view/dashboard'
      },

      {
        id:1.1,
        path: '/dashboard/store-dashboard'
      },
      {
        id:2,
        path: '/admin/table-booking'
      },
      {
        id:3,
        path: '/admin/booking-info'
      },
      {
        id:4,
        path: 'side-bar-view/menu',
      },
      {
        id:5,
        path: '/menu'
      },
      {
        id:6,
        path: '/menu/product'
      },
      {
        id:7,
        path: '/assets/table'
      },
      {
        id:6,
        path: '/settings/reservation-settings'
      },
      {
        id:7,
        path: '/settings/store-setting'
      },
      {
        id:8,
        path: '/settings/delivery-settings'
      },
      {
        id:9,
        path: '/settings/takeaway-settings'
      },
      {
        id:10,
        path: '/settings/pos-settings'
      },
      {
        id:11,
        path: '/settings/printer'
      },
      {
        id:12,
        path: '/settings/printer/printer-type'
      },
    ];
  }

  public static get roleIdMenu5() {
    return [

    ];
  }


  public static get roleIdMenu6() {
    return [
      {
        id:1,
        path :'/waiter-pos/pos-system'
      },
    ];
  }

  public static get roleIdMenu8() {
    return [
      {
        id:1,
        path :'/admin/pos-system'
      },
    ];
  }

  public static get roleIdMenu11() {
    return [
      {
        id: 1,
        path :'/dashboard/brand-dashboard'
      },
      {
        id: 2,
        path :'/loyalty'

      },
      {
        id: 3,
        path :'/loyalty/loyalty-menu-group'

      },
      {
        id: 4,
        path :'/loyalty/loyalty-type'

      },
      {
        id: 5,
        path :'/reports/loyalty-reports'

      },
      {
        id: 6,
        path :'/reports/loyalty-reports/customer-loyalty-report'

      },
      {
        id: 7,
        path :'/reports/loyalty-reports/loyalty-points-report'

      },
      {
        id: 8,
        path :'/reports/loyalty-reports/loyalty-points-log-report'

      },




    ];
  }


  // public static emailFormat = "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}";
  // public static mobileFormat = /[0-9\+\-\ ]/;
  // public static nameFormat = "[a-zA-Z\s .]+$";
  // public static telephoneFormat = /[0-9\+\-\ ]/;
  // public static numberFormat="[0-9]+"
  // public static decimalFormat="[0-9.]+"
  // public static alphanumricFormat="^[a-zA-Z0-9_.' ]*$"

  // public static productActivationDetails: any;
}
export function compare(
  a: number | string | Date,
  b: number | string | Date,
  isAsc: boolean
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
