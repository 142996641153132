import { Component, AfterViewInit, OnInit } from "@angular/core";
import { ROUTES } from "./menu-items";
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { COMPANYROUTES } from "./company-menu-items";
import { STOREROUTES } from "./store-menu-items";
import { STAFFROUTES } from "./staff-menu-items";
import { OFFICEROUTES } from "./office-menu-items";
import { CASHERROUTES} from "./casher-menu-items";
import { WAITERROUTES } from "./waiter-menu-items";
import { BRANDROUTES } from "./brand-menu-items";
import { interval, Subscription } from "rxjs";
import { SharedDataService } from "../services/shared-data.service";
import { OrderService } from "src/app/views/home/order.service";
//declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  showMenu = "";
  showSubMenu = "";
  expand = false;
  // public sidebarnavItems:RouteInfo[]=[];
  public sidebarnavItems = [];
  // this is for the open close
  addExpandClass(element: string, route) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }

    if (route.submenu.length != 0) {
      route.expand = route.expand ? false : true;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private orderService : OrderService,
    private data : SharedDataService
  ) {}




  // End open close
  // allorders: any = null;

  subscription: Subscription;

  getAllOrders(){
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.orderService.getStoreOrders(user.storeId).subscribe((orderdata) => {
      this.data.allorders = orderdata;
    })
  }
  playAudio() {
    if(this.data.allorders.filter(o => o.orderStatus === 'Placed').length > 0){
      let audio = new Audio();
      audio.src = "https://dashboard.edinemenu.com/assets/audios/alert.mp3";
      audio.load();
      audio.play();
    }

  }
  ngOnInit() {


//emit value in sequence every 10 second


    let user = JSON.parse(localStorage.getItem("currentUser"));




    if (user.roleId == 1) {
      this.sidebarnavItems = ROUTES.filter((sidebarnavItem) => sidebarnavItem);
    } else if (user.roleId == 2) {
      this.sidebarnavItems = COMPANYROUTES.filter(
        (sidebarnavItem) => sidebarnavItem
      );
    } else if (user.roleId == 3) {
      const source = interval(10000);
this.subscription = source.subscribe(val =>
  this.playAudio()
  );
      this.getAllOrders();
      this.sidebarnavItems = STOREROUTES.filter(
        (sidebarnavItem) => sidebarnavItem
      );
    } else if (user.roleId == 4) {
      this.sidebarnavItems = STAFFROUTES.filter(
        (sidebarnavItem) => sidebarnavItem
      );
    } else if (user.roleId == 5) {
      this.sidebarnavItems = OFFICEROUTES.filter(
        (sidebarnavItem) => sidebarnavItem
      );
    }
    //  else if (user.roleId == 6) {
    //   this.sidebarnavItems = WAITERROUTES.filter(
    //     (sidebarnavItem) => sidebarnavItem
    //   );
    // } else if (user.roleId == 8) {
    //   this.sidebarnavItems = CASHERROUTES.filter(
    //     (sidebarnavItem) => sidebarnavItem
    //   );
    // }

    else if (user.roleId == 11) {
      this.sidebarnavItems = BRANDROUTES.filter(
        (sidebarnavItem) => sidebarnavItem
      );
    }

    this.sidebarnavItems.forEach((element) => {
      element.expand = false;
    });

    let menuServiceActive: boolean = false;
    let reserveTableServiceActive: boolean = false;

    if (user.activeServices.length > 0) {
      user.activeServices.forEach(x =>{
        if(x.id == 1 && x.isActive == true){
          menuServiceActive = true;
        }
        if(x.id == 2 && x.isActive == true){
          reserveTableServiceActive = true;
        }

      })
    }

   // menuServiceActive = false;
   // reserveTableServiceActive = false;

    if (!menuServiceActive) {
      this.sidebarnavItems = this.sidebarnavItems.filter(
        (x) => x.title != "Menu"
      );
    }
   if(user.enableInventory == false){
      this.sidebarnavItems = this.sidebarnavItems.filter(
        (x) => x.title != "Inventory"
      );
   }

    for (let i = 0; i <= this.sidebarnavItems.length; i++) {
      let element = this.sidebarnavItems[i];
      if (element != undefined) {
        if (element.submenu != undefined) {
          for (let j = element.submenu.length; j >= 0; j--) {
            let innerElement = element.submenu[j];
            if (innerElement != undefined) {


              if (!menuServiceActive) {
                if (innerElement.title == "Delivery Settings") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Takeaway Settings") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Product Rating") {
                  element.submenu.splice(j, 1);
                }
              }
              if (!reserveTableServiceActive) {
                if (innerElement.title == "Reservation Settings") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Table Booking") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Booking Information") {
                  element.submenu.splice(j, 1);
                }
              }


              if(user.enableBulkSms ==  false){
                if (innerElement.title == "Send SMS") {
                  element.submenu.splice(j, 1);
                }
              }
              if(user.enableFeedbackForm ==  false){
                if (innerElement.title == "Feed Back") {
                  element.submenu.splice(j, 1);
                }
              }
              if(user.enableFeedbackForm ==  false){
                if (innerElement.title == "Feedback Form Report") {
                  element.submenu.splice(j, 1);
                }
              }
              if(user.enableBulkSms ==  false){
                if (innerElement.title == "SMS Log Report") {
                  element.submenu.splice(j, 1);
                }
              }


              if(!user.enableCatering){
                if (innerElement.title == "Catering Category") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Catering Products") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Catering Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Catering Payment Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Catering Sales Report") {
                  element.submenu.splice(j, 1);
                }
              }


              if (user.enableLoyalty == false) {
                if (innerElement.title == "Loyalty Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Customer Loyalty Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Loyalty Points Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Loyalty Points Log Report") {
                  element.submenu.splice(j, 1);
                }
              }

              if (user.enablePos == false) {
                if (innerElement.title == "POS System") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "POS Settings") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "No charge and Cancel") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Sales Report by Salesman") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Checkin Checkout Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Complementary Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Cancelled Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Void Sales Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Discount Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Expense Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Daily Sales Summary") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Sales Summary by Checkin") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Sales Summary Consolidated") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Advanced Sales Summary") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Checkin Checkout Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Credit Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Sales Summary Consolidated") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Sales Summary Consolidated") {
                  element.submenu.splice(j, 1);
                }



              }
              if (user.enableCustomPrinter == false) {
                if (innerElement.title == "Printer") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Printer Type") {
                  element.submenu.splice(j, 1);
                }
              }

              if (user.enableOfferCustomer == false) {
                if(innerElement.title == "Subscribed Customers"){
                  element.submenu.splice(j, 1);
                }
              }


              if (user.enableCombo == false) {
                if (innerElement.title == "Combo Categories") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Combo Products") {
                  element.submenu.splice(j, 1);
                }
              }

              // user.enableMenuGroup = false;

              if (user.enableMenuGroup == false) {
                if (innerElement.title == "Menu Groups") {
                  element.submenu.splice(j, 1);
                }
              }
              if (!user.storePosSettings.enableEmployeeLog) {
                if (innerElement.title == "Employees") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Employee Attendance Report") {
                  element.submenu.splice(j, 1);
                }
              }


              if (user.enableRating == false) {
                if (innerElement.title == "Store Rating") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Product Rating") {
                  element.submenu.splice(j, 1);
                }
              }


              if (user.enableAddon == false) {
                if (innerElement.title == "Addon Products") {
                  element.submenu.splice(j, 1);
                }
              }

              if (user.enableDelivery == false || (user.storeCanOrder == false && user.enablePos == false)) {
                if (innerElement.title == "Delivery Settings") {
                  element.submenu.splice(j, 1);
                }
              }


              if (user.storeCanOrder == false && user.enablePos == false) {
                // if (innerElement.title == "Delivery Settings") {
                //   element.submenu.splice(j, 1);
                // }
                if (innerElement.title == "Takeaway Settings") {
                  element.submenu.splice(j, 1);
                }
              }


              // if (user.storeCanOrder == false) {
              //   if (innerElement.title == "Delivery Settings") {
              //     element.submenu.splice(j, 1);
              //   }
              // }

              if (user.enableDeliveryBoy == false) {
                if (innerElement.title == "Driver Assign Report") {
                  element.submenu.splice(j, 1);
                }
                if (innerElement.title == "Driver Report") {
                  element.submenu.splice(j, 1);
                }
              }


              if (user.activeServices) {
                user.activeServices.forEach(x => {
                  if (x.serviceName === "Book a Table") {
                    if (x.isActive == false && user.storeCanOrder == false && user.enablePos == false) {
                      if (element.title == "Reports") {
                        if(innerElement.title == "Order History"){
                          element.submenu.splice(j, 1);
                        }

                        if(innerElement.title == "Table History"){
                          element.submenu.splice(j, 1);
                        }

                        if(innerElement.title == "Customer"){
                          element.submenu.splice(j, 1);
                        }
                      }
                    }
                  }
                });
              }


              if(user.storeCanOrder == false && user.enablePos == false && !menuServiceActive && !reserveTableServiceActive)
              {
                if(innerElement.title == "Table"){
                  element.submenu.splice(j, 1);
                }
              }


              if (user.storeCanOrder == false && user.enablePos == false) {
                if(innerElement.title == "Sales Report By Product"){
                  element.submenu.splice(j, 1);
                }

                if(innerElement.title == "Sales Report By Date"){
                  element.submenu.splice(j, 1);
                }

                if(innerElement.title == "Sales Summary Consolidated"){
                  element.submenu.splice(j, 1);
                }
              }



              // if(user.activeServices){
              //   user.activeServices.forEach(x => {
              //     if(x.serviceName === "Book a Table"){
              //       if(x.isActive == false){

              //         if(innerElement.title == "Order History"){
              //           element.submenu.splice(j, 1);
              //         }

              //         if(innerElement.title == "Table History"){
              //           element.submenu.splice(j, 1);
              //         }

              //         if(innerElement.title == "Customer"){
              //           element.submenu.splice(j, 1);
              //         }

              //       }
              //     }
              //   });
              // }

            }
          }
        }
      }
    }



     //observable for pushnotification
     this.data.PushNotification.subscribe(res =>{
      let data = res;
      // console.log(data);
      if(data === 'OrderProcessing'){
        this.getAllOrders();
        // console.log('call api');
      }
      else if(data === 'PreOrderProcessing'){
        // this.bindPreBookGrid();
        // console.log('call api');
      }
    });
    //end observable for pushnotification

  }
}
